import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Content from '../content';
import Date from '../date';
import Label from '../label/Label';

import './Events.css';

const Events = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: { sourceInstanceName: { eq: "events" } }
          sort: { order: DESC, fields: childMarkdownRemark___frontmatter___date }
        ) {
          edges {
            node {
              childMarkdownRemark {
                frontmatter {
                  name
                  date(formatString: "MMMM DD, YYYY")
                  site
                  slides
                  video
                  location
                  talkTitle
                }
              }
            }
          }
        }
      }
    `
  );

  const { edges } = data.allFile;

  return (
    <section>
      <Content wide>
        <div className="events">
          {edges.map((event, i) => {
            const { name, date, site, slides, video, location, talkTitle } = event.node.childMarkdownRemark.frontmatter;

            return (
              <div className="event" key={i}>
                <h2 className="event__headline">
                  <a href={site} className="event__headline-link">
                    {name}
                  </a>
                </h2>

                <Label className="event__detail" icon="edit">
                  {talkTitle}
                </Label>

                {/* <Label className="event__title">
                  <em>{talkTitle}</em>
                </Label> */}

                {/* <Label className="event__detail">
                  {talkTitle}
                </Label> */}

                <Date className="event__detail" date={date} />

                <Label className="event__detail" icon="pin">
                  {location}
                </Label>

                {slides ? (
                  <Label className="event__detail" icon="slides">
                    <a href={slides}>Slides</a>
                  </Label>
                ) : null}

                {video ? (
                  <Label className="event__detail" icon="film">
                    <a href={video}>Video</a>
                  </Label>
                ) : null}
              </div>
            );
          })}
        </div>
      </Content>
    </section>
  );
};

export default Events;
