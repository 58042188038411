import React from 'react';
import Layout from '../components/layout';
import Meta from '../components/meta';
import Content from '../components/content';
import Events from '../components/event';

const Notes = () => {
  return (
    <Layout>
      <Meta
        title="Speaking"
        description="When I have something to share I like to speak at conferences and meetups."
        image="/images/og-image-speaking.png"
      />

      <Content>
        <h1>Speaking</h1>
        <p>When I have something to share I like to speak at conferences and meetups.</p>
      </Content>

      <Events />
    </Layout>
  );
};

export default Notes;
